import { default as _91_46_46_46pageSlug_93CsJxmVuYsHMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/cms/pages/[...pageSlug].vue?macro=true";
import { default as activate4eKWwswQDaMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/activate.vue?macro=true";
import { default as index6M4kbpOvSvMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/index.vue?macro=true";
import { default as _91securityKey_93FWhPs00bY5Meta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as new2T5xiU1z3RMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/password/new.vue?macro=true";
import { default as watchlistrj9WRFkZW3Meta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/watchlist.vue?macro=true";
import { default as index1CEKRKbNqiMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/index.vue?macro=true";
import { default as editZo117qQLuwMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/edit.vue?macro=true";
import { default as indexwSttBwVGgYMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/index.vue?macro=true";
import { default as addQHOIiZgO9pMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/add.vue?macro=true";
import { default as rafflesEqphaxC0MQMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffles.vue?macro=true";
import { default as faq8pVprhX7RfMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue?macro=true";
import { default as indexkVyUhZimBFMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue?macro=true";
import { default as infoKI2cK39QPFMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue?macro=true";
import { default as _91showGroupSlug_93zhVWOXbtejMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue?macro=true";
import { default as programZO4vazj667Meta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue?macro=true";
import { default as shop2OaRko18JwMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue?macro=true";
import { default as _91showName_93GoqnPd64OpMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue?macro=true";
import { default as _91showSlug_938QOP795otIMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue?macro=true";
import { default as vouchersWVOOU96XvnMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue?macro=true";
import { default as _91cinemaSlug_930UmzgMCxNNMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue?macro=true";
import { default as indexKxyByt83TIMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/index.vue?macro=true";
import { default as _91citySlug_93QoDeaJGgoLMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug].vue?macro=true";
import { default as cinemasVp6G6XOSW5Meta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinemas.vue?macro=true";
import { default as citiesi5NQjpeo7KMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cities.vue?macro=true";
import { default as indextiCqOEBLxFMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/index.vue?macro=true";
import { default as _91movieSlug_931AjDm5zzXwMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movie/[movieSlug].vue?macro=true";
import { default as index0xfoBSZb96Meta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug]/index.vue?macro=true";
import { default as _91genreSlug_93ss0UQ7T0JGMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug].vue?macro=true";
import { default as genressM8wgdK5eBMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genres.vue?macro=true";
import { default as indexaX9GoTptHQMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/index.vue?macro=true";
import { default as indexlhIW7evBDlMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug]/index.vue?macro=true";
import { default as _91personSlug_93x34byHJq9DMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug].vue?macro=true";
import { default as personsm770pbpMu0Meta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/persons.vue?macro=true";
import { default as searchQrU4GeQeEWMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/search.vue?macro=true";
import { default as vouchersCFm7ksKCofMeta } from "/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/vouchers.vue?macro=true";
export default [
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: index1CEKRKbNqiMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: index1CEKRKbNqiMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: index1CEKRKbNqiMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: index1CEKRKbNqiMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: index1CEKRKbNqiMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: index1CEKRKbNqiMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: index1CEKRKbNqiMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: index1CEKRKbNqiMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: editZo117qQLuwMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: editZo117qQLuwMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: editZo117qQLuwMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: editZo117qQLuwMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: editZo117qQLuwMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: editZo117qQLuwMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: editZo117qQLuwMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: editZo117qQLuwMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: indexwSttBwVGgYMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: indexwSttBwVGgYMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: indexwSttBwVGgYMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: indexwSttBwVGgYMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: indexwSttBwVGgYMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: indexwSttBwVGgYMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: indexwSttBwVGgYMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: indexwSttBwVGgYMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addQHOIiZgO9pMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addQHOIiZgO9pMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addQHOIiZgO9pMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addQHOIiZgO9pMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addQHOIiZgO9pMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addQHOIiZgO9pMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addQHOIiZgO9pMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addQHOIiZgO9pMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: rafflesEqphaxC0MQMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: rafflesEqphaxC0MQMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: rafflesEqphaxC0MQMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: rafflesEqphaxC0MQMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: rafflesEqphaxC0MQMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: rafflesEqphaxC0MQMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: rafflesEqphaxC0MQMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: rafflesEqphaxC0MQMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: _91citySlug_93QoDeaJGgoLMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_930UmzgMCxNNMeta?.name,
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "",
    meta: indexkVyUhZimBFMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93QoDeaJGgoLMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_930UmzgMCxNNMeta?.name,
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "",
    meta: indexkVyUhZimBFMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93QoDeaJGgoLMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_930UmzgMCxNNMeta?.name,
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "",
    meta: indexkVyUhZimBFMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93QoDeaJGgoLMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_930UmzgMCxNNMeta?.name,
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "",
    meta: indexkVyUhZimBFMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93QoDeaJGgoLMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_930UmzgMCxNNMeta?.name,
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "",
    meta: indexkVyUhZimBFMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93QoDeaJGgoLMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_930UmzgMCxNNMeta?.name,
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "",
    meta: indexkVyUhZimBFMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93QoDeaJGgoLMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_930UmzgMCxNNMeta?.name,
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "",
    meta: indexkVyUhZimBFMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93QoDeaJGgoLMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_930UmzgMCxNNMeta?.name,
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "",
    meta: indexkVyUhZimBFMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: _91genreSlug_93ss0UQ7T0JGMeta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93ss0UQ7T0JGMeta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93ss0UQ7T0JGMeta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93ss0UQ7T0JGMeta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93ss0UQ7T0JGMeta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93ss0UQ7T0JGMeta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93ss0UQ7T0JGMeta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93ss0UQ7T0JGMeta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: _91personSlug_93x34byHJq9DMeta?.name,
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93x34byHJq9DMeta?.name,
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93x34byHJq9DMeta?.name,
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93x34byHJq9DMeta?.name,
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93x34byHJq9DMeta?.name,
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93x34byHJq9DMeta?.name,
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93x34byHJq9DMeta?.name,
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93x34byHJq9DMeta?.name,
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2024-12-06_08-07-17_692ae23/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  }
]